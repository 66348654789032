@import "../../../styles/variables";

.layout {
  position: relative;

  &__container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding-top: 5rem;
    transition: margin-left $transitionDuration;
  }

  &__main {
    max-width: 1200px;
    flex: 1 1 auto;
    margin: 0 auto 1rem auto;

    &_large {
      max-width: 1600px !important;
    }

    &_medium {
      max-width: 1420px !important;
    }

    &__header {
      margin: 0.7rem 0 0.5rem 0;

      &__breadcrumps {
        &_plr0 {
          @media (min-width: 425px) {
            :global(.p-breadcrumb) {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }

        @media (max-width: 425px) {
          font-size: 0.8rem;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
    }
  }

  &__global_spinner_container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
  }

  @media (max-width: 1440px) {
    &__main {
      &_medium {
        margin: 0 1rem 1rem 1rem;
      }
    }
  }

  @media (max-width: 1600px) {
    &__main {
      &_large {
        margin: 0 1rem 1rem 1rem;
      }
    }
  }

  @media (max-width: 768px) {
    &__main {
      margin: 0 1rem 2rem 1rem;
    }
  }

  @media (min-width: 1260px) {
    &__main {
      min-width: 1200px;
    }
  }

  @media (min-width: 1420px) {
    &__main_large {
      min-width: 1350px;
    }

    &__main_medium {
      min-width: 1350px;
    }
  }

  @media (min-width: 1600px) {
    &__main_large {
      min-width: 1550px;
      max-width: 1550px !important;
    }
  }

  @media (min-width: 1900px) {
    &__main_large {
      min-width: 1850px;
      max-width: 1850px !important;
    }
  }
}

.lds_ring {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
}

.lds_ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  margin: 8px;
  border: 8px solid var(--primary-color);
  border-radius: 50%;
  animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary-color) transparent transparent transparent;
}

.lds_ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds_ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds_ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds_ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
