@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.topBar {
  position: fixed;
  height: 5rem;
  z-index: 997;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 2rem;
  background-color: var(--surface-card);
  transition: left $transitionDuration;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .02), 0 0 2px rgba(0, 0, 0, .05), 0 1px 4px rgba(0, 0, 0, .08);

  &__progress {
    width: 100%;
    height: 6px;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__logo {
    display: flex;
    align-items: center;

    img {
      cursor: pointer;
      margin-right: .5rem;
      margin-top: .5rem;
      height: 7rem;
    }

    &:focus {
      @include focused();
    }

    &__home_button {
      display: none !important;

      @media (max-width: 920px) {
        &_full {
          display: inline-flex !important;
        }
      }

      @media (max-width: 900px) {
        &_small {
          display: inline-flex !important;
        }
      }
    }
  }

  @media (max-width: 460px) {
    &__logo {
      img {
        display: none !important;
      }
    }
  }

  &__left_menu {
    margin: 0 auto 0 auto;
    padding: 0;
    display: flex;
    align-items: center;
    height: 5rem;

    &__item {
      height: 100%;
      min-width: 164px;

      button {
        color: var(--text-color-secondary) !important;
      }

      .topBar__left_menu &_active {
        border-bottom-style: solid;
        border-bottom-width: 2px !important;
        border-bottom-color: var(--primary-color) !important;

        button {
          font-weight: bold;
        }
      }
    }

    &__home_link {
      display: none;

      li {
        display: none;
      }
    }

    button {
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    @media (max-width: 920px) {
      &_full {
        display: none !important;
      }
    }

    @media (max-width: 900px) {
      &_small {
        display: none !important;
      }
    }
  }

  &__button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text-color-secondary);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    transition: background-color $transitionDuration;

    &:hover {
      color: var(--text-color);
      background-color: var(--surface-hover);
    }

    &:focus {
      @include focused();
    }

    i {
      font-size: 1.5rem;
    }

    span {
      font-size: 1rem;
      display: none;
    }
  }

  &__displayButton {
    span {
      font-size: 1rem;
      display: block;
    }
  }

  &__profile_logo_admin {
    border: solid 2px darkred;
  }

  &__profile_logo_coordinator {
    border: solid 2px darkgreen;
  }

  &__profile_logo_editor {
    border: solid 2px deeppink;
  }

  &__profile_logo_online_editor {
    border: solid 2px #1447ff;
  }

  &__menu {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;

    .topBar__button {
      margin-left: 1rem;
    }
  }
}
