.CenterAlertWithText {
  height: 50vh;
  position: relative;

  &__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 50%;

    @media (max-width: 64em) {
      width: 75%;
    }

    &_header {
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0;
      text-align: left;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
}
