@import '../../../../styles/mixins';

.footer {
  display: block;
}

.footer_list {
  display: grid;
  margin: 0;
  padding: 0;

  list-style: none;

  grid-template-columns: repeat(5, 1fr);
}

.footer_item {
  display: block;
}

.footer_item_abs {
  position: absolute;
  left: 5px;
  bottom: 5px;
  font-size: 0.6rem;

  @media (max-width: 1440px) {
    font-size: 0.9rem;
  }
  @media (max-width: 1024px) {
    font-size: 0.6rem;
  }
  @media (max-width: 768px) {
    font-size: 0.6rem;
    bottom: 110px;
    left: 30px;
  }
  @media (max-width: 425px) {
    font-size: 0.6rem;
    bottom: 58px;
    left: 16px;
  }
}

.footer_link {
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-down(640px) {
    height: 50px;
  }
  @include media-up(640px) {
    height: 100px;
  }
}

.footer_link_jug {
  background-color: #ffffff;
}

.footer_link_vk {
  background-color: #2787f5;
}

.footer_link_facebook {
  background-color: #3b5998;
}

.footer_link_twitter {
  background-color: #00acee;
}

.footer_link_telegram {
  background-color: #0088cc;
}

.footer_link_habr {
  background-color: #78a2b7;

  .footer_icon {
    @include media-down(640px) {
      width: 38px;
      height: 13px;
    }
    @include media-up(640px) {
      width: 72px;
      height: 24px;
    }
  }
}

.footer_image {
  display: block;

  @include media-down(640px) {
    width: 80%;
    height: auto;
  }
}

.footer_icon {
  display: block;

  fill: #ffffff;
  @include media-down(640px) {
    width: 20px;
    height: 20px;
  }
  @include media-up(640px) {
    width: 32px;
    height: 32px;
  }
}
